import React from 'react';
import {
  ColorDataTableCode,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const ReactNativeTab = ({ data }) => {
  return (
    <PlatformTab>
      <Paragraph>Use the values below.</Paragraph>
      <Section>
        <ColorDataTableCode data={data} platform="reactnative" />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
